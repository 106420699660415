<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Upload Video</h3>
        <button class="btn btn-control close-btn" @click="$emit('close')">
          <img alt="" src="@/assets/icons/icon-close.png" />
        </button>
      </div>
      <div class="modal-body">
        <page-spinner v-if="ui.loading" />
        <div class="modal-body-container" v-if="!ui.hasFile && !ui.loading">
          <div class="form-group video-url">
            <label for="videoUrlField">Video from URL</label>
            <div class="d-flex">
              <input type="url" class="form-control" v-model="formData.videoUrl" placeholder="https://"/>
              <base-button title="Upload Video" action="primary" @click-btn="uploadVideo" :disabled="formData.videoUrl < 10" class="ms-2" />
            </div>
          </div>
          <div v-if="ui.invalidFormat" class="form-group full-width mt-2 text-danger">
            Invalid File Format, Supported format is ({{ this.supportedFormat.join(', ') }})
          </div>
          <div @drop.prevent="handleDrop" @dragover.prevent @:dragleave.prevent>
            <div class="scanned-doc position-relative text-center">
              <img src="@/assets/icons/Illustration-Upload Border.svg" alt=""/>
              <input type="file" ref="file" class="position-absolute" @change="submitFiles" accept="video/*" />
              <div class="scanned-doc-text" v-if="file.length > 0">
                <span>{{ file.length }}</span> file{{ file.length > 1 ? "(s)" : "" }}
                selected
              </div>
              <div class="scanned-doc-text" v-else>
                Drag File here or <span>Browse</span>
              </div>
              <base-button title="Select Video" action="secondary-default" class="mt-2 mx-auto"
                           @click-btn="$el.querySelector('input[type=file]').click()" />
            </div>
          </div>
        </div>
        <div class="modal-body-container" v-else-if="ui.hasFile && !ui.loading">
          <div class="upload-status" v-if="ui.showProgressBar">
            <div class="upload-status-text d-flex justify-content-between align-items-center">
              <div v-if="progress < 100">
                Upload in progress ({{ progress }}%)
              </div>
              <div v-else>Uploaded</div>
              <div>
                <span class="loaded-width">{{ ui.loadedFileWidth }}</span>
                / {{ ui.fileWidth }} mb
              </div>
            </div>
            <div class="upload-status-bar">
              <div class="upload-status-bar-process"></div>
            </div>
          </div>
          <div class="row mt-3">
            <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>
          </div>
          <div class="row mt-3">
            <div class="col-lg-6">
              <div class="form-group w-100 full-width">
                <label for="titleField">Title</label>
                <input type="text" id="titleField" class="form-control w-100 full-width"
                       placeholder="Enter Title" v-model="formData.title"/>
              </div>
              <div class="form-group mt-3 w-100 full-width">
                <label for="descField">Description</label>
                <textarea
                  id="descField"
                  cols="30"
                  rows="10"
                  class="form-control full-width"
                  v-model="formData.description"
                ></textarea>
              </div>
              <div class="mt-3 form-group">
                <label>Privacy</label>
                <multiselect
                  v-model="formData.privacy"
                  placeholder="Privacy"
                  class="full-width"
                  :options="privacyTypes"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <label>Preview</label>
              <video ref="video" controls controlsList="nodownload">
                <source :src="videoUrl" >
              </video>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" v-if="ui.canSave">
        <base-button title="Upload Video" action="secondary-default" :disabled="formData.title.length === 0"
                     @click-btn="submitVideo" :loading="ui.creating" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from '../../../../components/pageSpinner.vue';
import ValidationErrorsList from "../../../../components/form/ValidationErrorsList";
import BaseButton from '../../../../components/BaseButton.vue';

export default {
  name: "UploadTrainingVideoModal",
  components: {Multiselect, PageSpinner, ValidationErrorsList, BaseButton},
  data() {
    return {
      file: [],
      link: "",
      progress: 0,
      img: {
        src: ''
      },
      ui: {
        hasFile: false,
        loadedFileWidth: 0,
        fileWidth: 0,
        thumbail: "",
        canSave: false,
        loading: false,
        loadingThubnail: false,
        showProgressBar: false,
        creating: false,
        invalidFormat: false,
        form: {
          loading: false,
          isSuccess: false,
          isError: false,
          error: '',
          errors: [],
        }
      },
      privacyTypes: [],
      formData: {
        privacy: "",
        title: "",
        description: "",
        videoUrl: "",
      },
      videoUrl: '',
      supportedFormat: ['mp4', 'webm', 'ogg']
    };
  },
  beforeMount() {
    this.getPrivacyType();
  },
  methods: {
    handleDrop(e) {
      this.$refs.file.files = e.dataTransfer.files;
      this.submitFiles();
    },
    submitFiles() {
      this.file = [...this.$refs.file.files];
      this.ui.invalidFormat = false;

      if (this.file.length > 0) {
        const fileExt = this.file[0].name.split('.').pop().toLowerCase();
        if (!this.supportedFormat.includes(fileExt)) {
          this.file = [];
          this.ui.invalidFormat = true;
          return;
        }

        this.ui.fileWidth = (this.file[0].size / (1024 * 1024)).toFixed(2);
        this.uploadVideo();
      }
    },
    generateThumbnail(videoUrl = null) {
      const file = this.file[0];
      this.ui.loadingThubnail = true;
      this.videoUrl = videoUrl !== null ? videoUrl : URL.createObjectURL(file);
      this.ui.loadingThubnail = false;
    },
    fillProgressBar() {
      this.ui.showProgressBar = true;
      const file = this.file[0];

      return new Promise((resolve) => {
        for (let i = 1; i <= 10; i++) {
          const progress = this.progress = i * 10;
          setTimeout(() => {
            this.ui.loadedFileWidth = ((file.size / 10 ) * i / (1024 * 1024)).toFixed(2);
            document.getElementsByClassName("upload-status-bar-process")[0].style.width = `${progress}%`;
          }, i * 100)
        }
        resolve();
      });
    },
    uploadVideo() {
      this.ui.hasFile = true;
      this.ui.invalidFormat = false;
      if (this.formData.videoUrl.length > 0) {
        const url = this.formData.videoUrl;
        if (!this.supportedFormat.includes(url.split(".").pop().toLowerCase())) {
          this.ui.invalidFormat = true;
          this.ui.hasFile = false;
          this.ui.canSave = false;
          return;
        }
        this.generateThumbnail(this.formData.videoUrl);
        this.ui.canSave = true;
        return;
      }

      this.fillProgressBar().then(() => {
        this.generateThumbnail();
        this.ui.canSave = true;
      });
    },
    submitVideo() {
      let formData = new FormData()
      formData.append('file', this.file[0])
      for (const iterator in this.formData) {
        formData.append(iterator, this.formData[iterator])
      }
      this.ui.creating = true;
      this.ui.form.isError = false
      this.ui.form.error = ''
      this.ui.form.errors = []
      this.$http
        .post("/api/v1/training-video/create", formData)
        .then((res) => {
          this.pushAlert("success", res.data.message);
          this.ui.creating = false;
          this.$emit('created');
        })
        .catch((err) => {
          this.ui.creating = false;
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.isError = true
          this.ui.form.error = validation.error
          this.ui.form.errors = validation.errors

          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: this.ui.form.error,
            status: 'error',
            delay: 2000
          });
        });
    },
    getPrivacyType() {
      this.ui.loading = true;
      this.$http.get("/api/v1/training-video/privacy")
        .then((res) => {
          this.privacyTypes = res.data.data;
          this.formData.privacy = res.data.data[0];
          this.ui.loading = false;
        })
        .catch(() => { this.ui.loading = false; });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-footer {
    position: initial !important;
  }
  &-block {
    margin: 100px auto !important;
    height: fit-content !important;

    .modal-body {
      padding-bottom: 40px;
      margin-bottom: 0;

      &-container {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
    }
  }
}

.video-url {
  max-width: 100%;

  .btn {
    height: 40px;
    max-height: 40px;
    min-width: fit-content;
    margin-left: 12px;
  }

  .form-control {
    height: 40px;
    width: 80%;
  }
}

.scanned-doc {
  padding: 72px 0;
  margin-top: 24px;

  &-text {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    margin: 24px 0 20px 0;
  }

  .btn {
    &.bg-primary {
      height: 36px;
      width: 124px;
      border-radius: 8px;
      background-color: #0057ed;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  input {
    height: 100%;
    display: flex;
    top: 0;
  }
}

.upload-status {
  &-text {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }

  &-bar {
    position: relative;
    width: 100%;
    height: 12px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    margin-top: 12px;

    &-process {
      position: absolute;
      height: 12px;
      background-color: #0078ff;
      transition: all 0.3s;
    }
  }

  .loaded-width {
    opacity: 0.5;
  }
}

canvas {
  width: 100%;
  height: 200px;
}

video {
  z-index: -999999;
  width: 100%;
  height: 200px;
}
</style>
